@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.toast {
  top: 3rem !important;
  text-transform: capitalize;
}

.pac-item {
  font-size: 12px;
  color: #d9d9d9;
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
  position: relative;
  top: 5px;
  /* border-radius: 5px; */
  padding: 10px;
}

.pac-item:hover {
  background-color: red;
}

.pac-container {
  /* position: absolute; */
  width: 100%;
  bottom: -50px;
  z-index: 1000;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* overflow: hidden; */
}

/* ////////////////////////////////// */

.bounce {
  animation: bounce 1s ease-in-out infinite;
}
.bounce-inverted {
  animation: bounce2 1s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}
@keyframes bounce2 {
  0% {
    transform: rotate(180deg) translateY(5px);
  }
  50% {
    transform: rotate(180deg) translateY(0px);
  }
  100% {
    transform: rotate(180deg) translateY(5px);
  }
}

.hov {
  position: relative;
  padding: 5px;
}

.hov:hover {
  background-color: white;
  color: #fe7474;
}

a:before {
  content: '';
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-width: 0 0 2px 2px;
  opacity: 0;
  transition: all 0.3s;
}

.hov:hover:before {
  opacity: 1;
  bottom: -8px;
  left: -8px;
}

.hov:after {
  content: '';
  position: absolute;
  top: 12px;
  right: 12px;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-width: 2px 2px 0 0;
  opacity: 0;
  transition: all 0.3s;
}

.hov:hover:after {
  opacity: 1;
  top: -8px;
  right: -8px;
}

.hov.active:before {
  opacity: 1;
  bottom: -8px;
  left: -8px;
}

.hov.active:after {
  opacity: 1;
  top: -8px;
  right: -8px;
}

.hov.active {
  background-color: white;
  color: #fe7474;
}
